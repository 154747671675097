<template>
  <div class="SystemMonitor page">
    <div class="item">
      <div class="title">网页磁盘</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in web_data"
          :key="index"
        >
          <span class="span">{{ item[0] }}</span>
          <a-progress :percent="parseInt(item[4])" status="active" />
          <span>已用{{ item[2] }},共{{ item[1] }}</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="title">FS磁盘</div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, index) in fs_data"
          :key="index"
        >
          <span class="span">{{ item[0] }}</span>
          <a-progress :percent="parseInt(item[4])" status="active" />
          <span>已用{{ item[2] }},共{{ item[1] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import { getSystem } from "../../service/operationlog";
export default {
  name: "SystemMonitor",
  setup() {
    const state = reactive({
      web_data: [],
      fs_data: [],
    });
    onMounted(async () => {
      const res = await getSystem({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.web_data = res.data.data.web_data;
      state.fs_data = res.data.data.fs_data;
    });
    return { ...toRefs(state) };
  },
  components: {},
};
</script>
<style  scoped>
.SystemMonitor {
  padding: 10px;
}
.title {
  font-size: 25px;
  color: #666;
}
.content-item {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.content .content-item:nth-child(2n + 1) {
  margin-right: 10%;
}
.content {
  margin: 10px 60px;
  display: flex;
  flex-wrap: wrap;
}
.ant-progress {
  height: 15px;
}
.span {
  margin-bottom: -10px;
}
</style>